import React from "react"
import Layout from "../components/layout.js"
import Meta from "../components/meta.js"
export default function comingSoon() {
  return (
    <Layout>
      <Meta />
      <h1>
          Coming soon
      </h1>

      <p>This page will be available soon. Check back later :)</p>
    </Layout>
  )
}
